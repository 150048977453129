import { parse } from "query-string";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import {
  FUNNEL_DESIGNS,
  THUMBNAIL_TYPE,
  MOBILE_BACKGROUND_WIDTH
} from "./strings";
import { Greet } from "../components/Greet";
import { redirect } from "../services/utils";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import OutcomePage from "../components/OutcomePage";
import PageNotFound from "../components/PageNotFound";
import { ZipCodeForm } from "../components/ZipCodeForm";
import ModernVariant from "../components/ModernVariant";
import { QuestionComponent } from "../components/Question";
import { useRecordAction } from "../hooks/useRecordAction";
import { setFunnel, updateQA } from "../store/slices/funnelInfo";
import { fetchFunnelBySite, fetchSenderId } from "../services/api";
import { ZipCodeError } from "../components/ZipCodeForm/ZipCodeError";
import { loadAnalyticsHead, loadAnalyticsBody } from "../services/helper";
import { ContactInfoForm } from "../components/ContactInfoForm/ContactForm";

const Background = styled.div`
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url(${({ bgImage }) => bgImage});
  font-family: "Roboto", sans-serif;
`;
const Main = styled.div`
  width: 100%;
  padding: 0px 20px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
`;
const QuestionContainer = styled.div`
  width: 580px;
  max-width: 580px;
  background-color: white;
  padding-bottom: 50px;
  border-radius: 6px;
  box-shadow: 0px 0px 2px 0px #9e9e9e;

  @media (max-width: 660px) {
    width: 100%;
  }
`;
const SpinnerContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledSpinner = styled(Spinner)`
  width: 60px;
  height: 60px;
`;

const IndexPage = () => {
  const dispatch = useDispatch();
  const { handleRecordAction, handleRemoveAction } = useRecordAction();
  const { funnelInfo, zipInfo } = useSelector((state) => state);
  const { funnel, doRemove } = useSelector((state) => state.funnelInfo);
  const isTestMode =
    parse(window.location.search).testMode === "true" ? true : false;
  const [leadError, setLeadError] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [shouldDisplayOutcomeAtEnd, setShouldDisplayOutcomeAtEnd] =
    useState(false);
  const [isOutcomeLoading, setIsOutcomeLoading] = useState(true);

  const isMobileBackground =
    window.document.body.offsetWidth <= MOBILE_BACKGROUND_WIDTH;

  const progress = Math.round(
    (funnelInfo.currentQuestion / funnelInfo.allQuestions.length) * 100
  );

  const question = funnelInfo.allQuestions.find(
    (quest) => quest?.position === funnelInfo.currentQuestion
  );

  // console.log({
  //   qResponse: funnelInfo.qResponses,
  //   selectedAnswers: funnelInfo.selectedAnswers,
  //   allQuestions: funnelInfo.allQuestions
  // });

  const getSiteFunnel = async () => {
    try {
      let domain = "";
      let subDomain = "";
      if (
        parse(window.location.search).domain &&
        parse(window.location.search).subDomain
      ) {
        domain = parse(window.location.search).domain;
        subDomain = parse(window.location.search).subDomain;
      } else {
        const splittedHostname = window.location.hostname.split(/[,.\s]/);
        subDomain = splittedHostname[0];
        domain = `${splittedHostname[1]}.${splittedHostname[2]}`;
      }
      let senderId = localStorage.getItem("senderId");
      if (!senderId) {
        const uuidRes = await fetchSenderId();
        senderId = uuidRes.data.data.senderId;
        localStorage.setItem("senderId", senderId);
      }
      const serachParams = parse(window.location.search);
      const fbClId = serachParams.fbclid;
      const adData = {
        campaign_id: serachParams.am_campaign_id,
        campaign_name: serachParams.am_campaign_name,
        adset_id: serachParams.am_adset_id,
        adset_name: serachParams.am_adset_name,
        ad_id: serachParams.am_ad_id,
        ad_name: serachParams.am_ad_name
      };
      const response = await fetchFunnelBySite({
        fbClId,
        adData,
        domain,
        senderId,
        subDomain,
        sourceUrl: window.location.href
      });
      dispatch(setFunnel(response.data.data));
      if (response.data.data.funnelQA.length) {
        dispatch(updateQA(response.data.data.funnelQA));
      }
      loadAnalyticsHead(response);
      loadAnalyticsBody(response);
    } catch (err) {
      console.log("Error in fetch funnel", err);
    } finally {
      setIsPageLoading(false);
    }
  };

  const handleOutcomeLoader = () => {
    setTimeout(() => {
      setIsOutcomeLoading(false);
    }, 2000);
  };

  const getFunnelCb = useCallback(getSiteFunnel, [dispatch]);

  useEffect(() => {
    if (funnel.companyInfo?.companyName) {
      document.title = funnel.companyInfo.companyName;
    }
  }, [funnel]);

  useEffect(() => {
    getFunnelCb();
  }, [getFunnelCb]);

  useEffect(() => {
    if (
      funnelInfo.outcome &&
      funnelInfo.outcome?.outcomeLogic === "end" &&
      funnelInfo.allQuestions.length === funnelInfo.currentQuestion
    ) {
      setShouldDisplayOutcomeAtEnd(true);
      handleOutcomeLoader();
    }
  }, [funnelInfo]);

  useEffect(() => {
    if (
      funnelInfo.outcome?.outcomeLogic === "directly" &&
      funnel.funnelDesign === FUNNEL_DESIGNS.CLASSIC
    ) {
      handleOutcomeLoader();
    }
  }, [funnelInfo.outcome?.outcomeLogic, funnel]);

  // RECORD INSIGHT
  useEffect(() => {
    if (!Object.keys(funnelInfo.recordActionState)?.length || isTestMode)
      return;
    handleRecordAction();
  }, [funnelInfo.recordActionState, handleRecordAction, isTestMode]);

  const questionToRemove = funnelInfo.allQuestions.find(
    (quest) => quest?.position === funnelInfo.questionToRemove
  );
  //REMOVE INSIGHT
  useEffect(() => {
    if (isTestMode || !doRemove) return;
    handleRemoveAction(questionToRemove?._id);
  }, [question, handleRemoveAction, isTestMode, doRemove, questionToRemove]);

  return isPageLoading ? (
    <SpinnerContainer>
      <StyledSpinner animation="border" variant="secondary" />
    </SpinnerContainer>
  ) : !funnel && !isPageLoading ? (
    <PageNotFound />
  ) : funnelInfo.leadForm.isSubmitted &&
    funnel.thankyouPage?.redirectLead &&
    funnel.thankyouPage?.redirectUrl ? (
    redirect({
      redirectUrl: funnel.thankyouPage.redirectUrl,
      redirectUrlParams: funnelInfo.redirectUrlParams
    })
  ) : (
    <>
      {/* test mode banner */}
      {isTestMode && (
        <div
          style={{ zIndex: "9999" }}
          className="sticky-top py-2 bg-danger text-white text-center fw-bold"
        >
          DU BEFINDEST DICH IM TEST MODUS
        </div>
      )}
      {funnel.funnelDesign === FUNNEL_DESIGNS.MODERN ? (
        <ModernVariant
          question={question}
          progress={progress}
          isTestMode={isTestMode}
        />
      ) : (
        // CLASSIC Variant
        <Background
          bgImage={`${process.env.REACT_APP_ASSET_CDN_URL}/${
            funnel.design?.backgroundImage?.key
          }/${
            isMobileBackground
              ? THUMBNAIL_TYPE.MOBILE_BACKGROUND
              : THUMBNAIL_TYPE.BACKGROUND
          }`}
        >
          {/* <div className="sticky-top py-2 bg-danger text-white text-center fw-bold">
        DU BEFINDEST DICH IM TEST MODUS
      </div> */}
          <Main>
            <Header />
            {funnelInfo.outcome.outcomeLogic === "directly" ||
            shouldDisplayOutcomeAtEnd ? (
              <OutcomePage
                outcome={funnelInfo.outcome}
                isLoading={isOutcomeLoading}
                loaderColor={funnel.design?.baseColor}
              />
            ) : (
              <QuestionContainer>
                {funnelInfo.currentQuestion <
                  funnelInfo.allQuestions.length && (
                  <QuestionComponent
                    progress={progress}
                    questionData={question}
                  />
                )}
                {funnelInfo.currentQuestion ===
                  funnelInfo.allQuestions.length &&
                  !zipInfo.isSubmitted && <ZipCodeForm />}
                {zipInfo.isSubmitted &&
                  !funnelInfo.leadForm.isSubmitted &&
                  (zipInfo.filterData?.shouldCreate ? (
                    <ContactInfoForm setLeadError={setLeadError} />
                  ) : (
                    <ZipCodeError />
                  ))}
                {funnelInfo.leadForm.isSubmitted &&
                  !funnel.thankyouPage?.redirectLead && (
                    <Greet leadError={leadError} />
                  )}
              </QuestionContainer>
            )}
            <Footer />
          </Main>
        </Background>
      )}
    </>
  );
};

export default IndexPage;
