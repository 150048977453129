import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const saveLeadResponse = async (leadRespData) => {
  try {
    const response = await axios.post(`${baseUrl}/lead-response`, leadRespData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchFunnelBySite = async (paramsObj) => {
  try {
    const response = await axios.get(`${baseUrl}/funnel/site`, {
      params: paramsObj
    });
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return Promise.reject(error);
  }
};

export const fetchSenderId = async () => {
  try {
    const response = await axios.get(`${baseUrl}/funnel-insight/senderId`);
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return Promise.reject(error);
  }
};

export const checkPostalCode = async (funnelId, postalCode) => {
  try {
    const response = await axios.get(
      `${baseUrl}/funnel/site/check-postalcode`,
      {
        params: { funnelId, postalCode }
      }
    );
    return response.data.data;
  } catch (error) {
    console.log("ERROR: ", error);
    return Promise.reject(error);
  }
};

export const saveRecordAction = async (payload) => {
  try {
    const response = await axios.post(
      `${baseUrl}/funnel-insight/recordAction`,
      payload
    );
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return Promise.reject(error);
  }
};

export const recordWebsiteVisit = async (payload) => {
  try {
    const response = await axios.post(
      `${baseUrl}/funnel-insight/website/record`,
      payload
    );
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return Promise.reject(error);
  }
};

export const deleteQuestionVisit = async (payload) => {
  try {
    const response = await axios.post(
      `${baseUrl}/funnel-insight/remove-insight`,
      payload
    );
    return response;
  } catch (error) {
    console.log("ERROR: ", error);
    return Promise.reject(error);
  }
};
