import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  faUser,
  faPhone,
  faEnvelope,
  faCaretRight
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Field from "./FormField";
import {
  FormSubText,
  FormHeadline,
  SubmitButton,
  LoaderContainer,
  FormSubHeadline,
  FormInnerContainer
} from "./ContactInfoForm.styles";
import PhoneInputField from "../PhoneInput";
import { getCountryCodes } from "../../services/utils";
import { useLeadAction } from "../../hooks/useLeadAction";
import { ContactFormBaseSchema } from "../../services/validation-schema";
import { useEmailDomainConfirmation } from "../../hooks/useEmailDomainConfirmation";

export const ContactInfoForm = ({ setLeadError }) => {
  const [isLoading, setIsLoading] = useState();
  const { emailDomainConfirmation, funnel } = useSelector(
    (state) => state.funnelInfo
  );
  const { handleEmailDomainConfirmation } = useEmailDomainConfirmation();
  const { checkPhoneNumberValidity, handleSubmitLeadInfo } = useLeadAction();
  const textElements = funnel.textElements;
  const {
    formScreenHeader,
    formScreenSubtext,
    formHeadline,
    subHeadlineForm,
    fieldTitleName,
    fieldPlaceholderName,
    fieldTitleEmail,
    fieldPlaceholderEmail,
    fieldTitlePhone,
    fieldPlaceholderPhone,
    submitButton,
    submitButtonSubtext,
    numberCheck
  } = textElements;
  const country = funnel.companyInfo?.country;

  const methods = useForm({
    resolver: yupResolver(ContactFormBaseSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: ""
    }
  });

  const {
    watch,
    handleSubmit,
    setError,
    formState: { errors }
  } = methods;

  const emailWatcher = watch("email");

  const onSubmit = (formValues) => {
    const isValidPhoneNumber = checkPhoneNumberValidity({
      phoneNumber: formValues.phone,
      numberCheck,
      setError
    });
    if (!isValidPhoneNumber) return;
    handleSubmitLeadInfo({
      formValues,
      setLeadError,
      setIsLoading
    });
  };

  useEffect(() => {
    handleEmailDomainConfirmation({
      hasError: errors?.email,
      emailValue: emailWatcher
    });
  }, [errors, emailWatcher, handleEmailDomainConfirmation]);

  return (
    <div>
      <div className="p-2 border border-1 border-white rounded-top bg-success text-center text-white">
        <p className="mb-0 fw-bolder">{formScreenHeader}</p>
        <FormSubText className="mb-0">{formScreenSubtext}</FormSubText>
      </div>
      <FormInnerContainer className="px-3 pt-3 mx-3 px-xsm-3 px-sm-3 px-xl-5 fs-6">
        <FormHeadline className="text-center px-5 fw-bolder fs-5">
          {formHeadline}
        </FormHeadline>
        <FormSubHeadline className="text-center fs-7">
          {subHeadlineForm}
        </FormSubHeadline>
        <FormProvider {...methods}>
          <form
            onSubmit={(event) => {
              handleSubmit(onSubmit)(event);
            }}
          >
            <Field
              field={{
                name: "name",
                title: fieldTitleName,
                hasError: errors?.name,
                icon: faUser,
                type: "text",
                placeholder: fieldPlaceholderName
              }}
            />
            <Field
              field={{
                name: "email",
                title: fieldTitleEmail,
                hasError: errors?.email,
                icon: faEnvelope,
                type: "email",
                placeholder: fieldPlaceholderEmail
              }}
              suggestion={emailDomainConfirmation}
            />

            <PhoneInputField
              field={{
                countries: getCountryCodes(country),
                icon: faPhone,
                name: "phone",
                title: fieldTitlePhone,
                hasError: errors?.phone,
                placeholder: fieldPlaceholderPhone
              }}
            />
            <div>
              <SubmitButton
                type="submit"
                className="w-100 mt-3 border-0 outline-0"
                disabled={isLoading}
              >
                <div>
                  <div className="fs-5 fw-bolder">
                    {submitButton}
                    <FontAwesomeIcon icon={faCaretRight} className="ms-2" />
                  </div>
                  <div style={{ fontSize: "14px", opacity: "0.7" }}>
                    {submitButtonSubtext}
                  </div>
                </div>
                {isLoading && (
                  <LoaderContainer>
                    <Spinner />
                  </LoaderContainer>
                )}
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      </FormInnerContainer>
    </div>
  );
};
