import styled from "styled-components";
import { Button } from "react-bootstrap";

export const FormSubText = styled.p`
  font-size: 14px;
  @media (max-width: 481px) {
    font-size: 12px;
  }
`;
export const FormHeadline = styled.p`
  @media (max-width: 481px) {
    padding: 0 !important;
    font-size: 16px !important;
  }
`;
export const FormSubHeadline = styled.p`
  white-space: break-spaces;
  @media (max-width: 481px) {
    font-size: 14px;
  }
`;
export const FormInnerContainer = styled.div`
  @media (max-width: 481px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

export const SubmitButton = styled(Button)`
  position: relative;
  background-color: #e15d3a;
  &: hover {
    background-color: #e15d3a !important;
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  bottom: 0;
  right: 12px;
  display: flex;
  position: absolute;
  align-items: center;
`;

export const SuggestionText = styled.div`
  font-size: 12px;
`;
