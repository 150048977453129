import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup, Form, InputGroup } from "react-bootstrap";
import ValidationMessage from "../ValidationMessage";
const PhoneInputField = ({ field }) => {
  return (
    <FormGroup controlId={field.name} className="mb-4">
      <Form.Label className={field.hasError && "text-danger"}>
        {field.title}:{field.hasError && "*"}
      </Form.Label>
      <InputGroup>
        <InputGroup.Text className={field.hasError && "bg-danger text-white"}>
          <FontAwesomeIcon icon={field.icon} />
        </InputGroup.Text>
        <PhoneInputWithCountry
          addInternationalOption={false}
          defaultCountry={field.countries[0]}
          countries={field.countries}
          name={field.name}
          placeholder={field.placeholder}
        />
      </InputGroup>
      <ValidationMessage message={field.hasError?.message} />
    </FormGroup>
  );
};

export default PhoneInputField;
