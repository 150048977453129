import { parse } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
  updateUrlParams,
  updateIsFormSubmitted,
  updateRecordActionState
} from "../store/slices/funnelInfo";
import { saveLeadResponse } from "../services/api";
import { INSIGHTS_TYPE } from "../templates/strings";
import {
  isValidPhoneNumber,
  normalizeNumber,
  removeWhiteSpaces
} from "../services/utils";
import { SuggestionText } from "../components/ContactInfoForm/ContactInfoForm.styles";

export const useLeadAction = () => {
  const dispatch = useDispatch();
  const { funnelInfo, zipInfo } = useSelector((state) => state);

  const invalidNumberMsg = () => (
    <SuggestionText className="mt-1">
      <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
      <span>Keine gültige Nummer</span>
    </SuggestionText>
  );

  const checkPhoneNumberValidity = ({ phoneNumber, numberCheck, setError }) => {
    // testing required!
    const isValid = isValidPhoneNumber(phoneNumber, numberCheck);
    if (!isValid) {
      setError("phone", { type: "custom", message: invalidNumberMsg() });
      return false;
    }
    return true;
  };

  const handleSubmitLeadInfo = async ({
    formValues,
    setLeadError,
    setIsLoading
  }) => {
    setIsLoading(true);
    try {
      const splittedName = formValues.name.split(" ");
      const firstName = splittedName[0];
      // remove whitespaces except actual ones
      const lastName =
        removeWhiteSpaces({ string: splittedName.slice(1).join(" ") }) || "";
      dispatch(
        updateUrlParams({
          email: formValues.email,
          firstName,
          lastName,
          phone: normalizeNumber(formValues.phone)
        })
      );
      const serachParams = parse(window.location.search);
      const fbClId = serachParams.fbclid;
      const dynamicSource = serachParams.source?.toLowerCase();
      const testMode = serachParams.testMode === "true" ? true : false;
      const leadPayload = {
        clickId: fbClId,
        testMode,
        sourceUrl: window.location.href,
        leadService: funnelInfo.funnel.leadService,
        lead: {
          funnelId: funnelInfo.funnel._id,
          funnelVersion: funnelInfo.funnel.activeVersion,
          funnelCustomer: funnelInfo.funnel.customer,
          productName:
            funnelInfo.funnel.leadServiceProductName ||
            funnelInfo.funnel.category.name,
          leadDetails: {
            leadName: formValues.name,
            leadEmail: formValues.email,
            leadPhone: normalizeNumber(formValues.phone),
            leadZip: zipInfo.value
          },
          adDetails: {
            campaign_id: serachParams.am_campaign_id,
            campaign_name: serachParams.am_campaign_name,
            adset_id: serachParams.am_adset_id,
            adset_name: serachParams.am_adset_name,
            ad_id: serachParams.am_ad_id,
            ad_name: serachParams.am_ad_name
          },
          responses: funnelInfo.qResponses,
          source: dynamicSource
        }
      };
      const response = await saveLeadResponse(leadPayload);
      dispatch(
        updateRecordActionState({
          insightRefId: INSIGHTS_TYPE.LEAD,
          insightType: INSIGHTS_TYPE.LEAD,
          nextInsightRefId: INSIGHTS_TYPE.LEAD_CREATED,
          nextInsightType: INSIGHTS_TYPE.LEAD_CREATED,
          leadId: response.data.data._id
        })
      );
    } catch (error) {
      setLeadError(error.message);
    } finally {
      // setTimeout for display loader
      setTimeout(() => {
        setIsLoading(false);
        dispatch(updateIsFormSubmitted(!funnelInfo.leadForm.isSubmitted));
      }, 3000);
    }
  };

  return { checkPhoneNumberValidity, handleSubmitLeadInfo };
};
