import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  ModernForm,
  FormFieldInfo,
  CenteredInput,
  PhoneInputStyles,
  ModernFormSection,
  FormFieldInfoPara,
  FormFieldInfoSpan,
  CenteredInputLabel,
  ModernFormContainer,
  FormFieldInfoHeading,
  ModerFormBtnContainer,
  UserConfirmationLabel,
  ModernFormFieldWrapper,
  ModernFormFieldsSection
} from "../ModernVariant.styles";
import {
  ModernFormSchema,
  ContactFormBaseSchema
} from "../../../services/validation-schema";
import { getCountryCodes } from "../../../services/utils";
import { useLeadAction } from "../../../hooks/useLeadAction";
import MoveBackButton from "../MoveButtons/move-back-button";
import MoveForwardButton from "../MoveButtons/move-forward-button";
import AnchorElement from "../../CommonVariantComponents/anchor-element";
import { SuggestionText } from "../../ContactInfoForm/ContactInfoForm.styles";
import { useEmailDomainConfirmation } from "../../../hooks/useEmailDomainConfirmation";
import { updateIsZipSubmitted } from "../../../store/slices/zipInfo";
import { updateDoRemove } from "../../../store/slices/funnelInfo";

const ModernLeadForm = ({
  setLeadError,
  isLeadSubmitting,
  setIsLeadSubmitting
}) => {
  const dispatch = useDispatch();
  const { handleEmailDomainConfirmation } = useEmailDomainConfirmation();
  const { checkPhoneNumberValidity, handleSubmitLeadInfo } = useLeadAction();
  const { emailDomainConfirmation, funnel } = useSelector(
    (state) => state.funnelInfo
  );
  const countries = getCountryCodes(funnel.companyInfo?.country);
  const company = funnel.companyInfo;
  const textElements = funnel.textElements;
  const {
    numberCheck,
    fieldTitleName,
    fieldTitleEmail,
    fieldTitlePhone,
    fieldPlaceholderName,
    fieldPlaceholderEmail,
    fieldPlaceholderPhone
  } = textElements;
  const { dataProtectionUrl, dataProtectionContent } = company;

  const methods = useForm({
    resolver: yupResolver(
      funnel.design?.modernFormPolicyDisplay
        ? ModernFormSchema
        : ContactFormBaseSchema
    ),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      policyCheck: false
    }
  });
  const {
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors }
  } = methods;
  console.log("errors ", errors);
  const emailWatcher = watch("email");

  const onSubmit = (formValues) => {
    const isValidPhoneNumber = checkPhoneNumberValidity({
      phoneNumber: formValues.phone,
      numberCheck,
      setError
    });
    if (!isValidPhoneNumber) return;
    handleSubmitLeadInfo({
      formValues,
      setLeadError,
      setIsLoading: setIsLeadSubmitting
    });
  };

  useEffect(() => {
    handleEmailDomainConfirmation({
      hasError: errors?.email,
      emailValue: emailWatcher
    });
  }, [errors, emailWatcher, handleEmailDomainConfirmation]);

  return (
    <>
      <ModernFormContainer className="mx-auto d-flex flex-column flex-md-row flex-nowrap justify-content-center align-items-stretch">
        <ModernFormSection className="position-relative d-flex flex-column flex-nowrap justify-content-start align-items-start">
          <FormFieldInfo
            className="w-100 text-white d-flex flex-column flex-nowrap justify-content-center align-items-end"
            background={funnel.design?.modernContrastColor || ""}
          >
            <FormFieldInfoHeading className="w-100 mb-0">
              {funnel.design?.modernFormInfoLabelOne ||
                "Nur noch ein letzter Schritt"}
            </FormFieldInfoHeading>
            <FormFieldInfoPara className="m-0 w-100">
              {funnel.design?.modernFormInfoLabelTwo ||
                "Damit wir Sie kontaktieren können, benötigen wir noch einige persönliche Daten."}
            </FormFieldInfoPara>
            <FormFieldInfoSpan className="d-none d-md-inline-flex align-items-center">
              {funnel.design?.modernFormInfoLabelThree || "Formular ausfüllen"}
              <FontAwesomeIcon icon={faArrowRight} />
            </FormFieldInfoSpan>
          </FormFieldInfo>
        </ModernFormSection>
        <ModernFormFieldsSection className="bg-white">
          <FormProvider {...methods}>
            <ModernForm>
              <FormFieldInfoHeading className="w-100 mb-0">
                {funnel.design?.modernFormHeading || "persönliche Daten"}
              </FormFieldInfoHeading>
              <ModernFormFieldWrapper>
                <CenteredInputLabel
                  className={`mt-0 mb-1 ${errors?.name && "text-danger"}`}
                >
                  {`${fieldTitleName || "Name"}*`}
                </CenteredInputLabel>
                <CenteredInput
                  type="text"
                  placeholder={fieldPlaceholderName}
                  {...register("name")}
                />
              </ModernFormFieldWrapper>
              <ModernFormFieldWrapper>
                <CenteredInputLabel
                  className={`mt-0 mb-1 ${
                    (errors?.email || emailDomainConfirmation) && "text-danger"
                  }`}
                >
                  {`${fieldTitleEmail || "E-Mail"}*`}
                </CenteredInputLabel>
                <CenteredInput
                  type="email"
                  placeholder={fieldPlaceholderEmail}
                  {...register("email")}
                />
                {emailDomainConfirmation && (
                  <SuggestionText className="text-danger">
                    Did you mean <span className="text-primary">.com</span>
                  </SuggestionText>
                )}
              </ModernFormFieldWrapper>
              <ModernFormFieldWrapper>
                <CenteredInputLabel
                  className={`mt-0 mb-1 ${errors?.phone && "text-danger"}`}
                >
                  {`${fieldTitlePhone || "Telefonnummer"}*`}
                </CenteredInputLabel>
                <PhoneInputStyles
                  addInternationalOption={false}
                  defaultCountry={countries[0]}
                  countries={countries}
                  name={"phone"}
                  placeholder={fieldPlaceholderPhone}
                />
                {errors?.phone?.type === "custom" && (
                  <small className="text-danger pt-1">
                    {errors.phone.message}
                  </small>
                )}
              </ModernFormFieldWrapper>

              <ModernFormFieldWrapper
                className={`mt-4 ${
                  funnel.design?.modernFormPolicyDisplay ? "d-flex" : "d-none"
                }`}
              >
                <Form.Check type="checkbox" {...register("policyCheck")} />
                <UserConfirmationLabel
                  className={`mt-1 ${errors?.policyCheck ? "text-danger" : ""}`}
                >
                  Ja, ich habe die{" "}
                  <AnchorElement
                    anchorText="Datenschutzerklärung"
                    modalData={{
                      heading: "Datenschutzerklärung",
                      content: dataProtectionContent
                    }}
                    color={funnel.design.modernBackgroundOne}
                    href={dataProtectionContent ? void 0 : dataProtectionUrl}
                  />{" "}
                  zur Kenntnis genommen und bin damit einverstanden, dass die
                  von mir angegebenen Daten elektronisch erhoben und gespeichert
                  werden. Meine Daten werden hierbei nur streng zweckgebunden
                  zur Bearbeitung und Beantwortung meiner Anfrage genutzt.
                </UserConfirmationLabel>
              </ModernFormFieldWrapper>
            </ModernForm>
          </FormProvider>
        </ModernFormFieldsSection>
      </ModernFormContainer>
      <ModerFormBtnContainer className="d-flex flex-row justify-content-between mx-auto">
        <MoveBackButton
          onBackClick={() => {
            dispatch(updateDoRemove({ isForm: true }));
            dispatch(updateIsZipSubmitted(false));
          }}
          btnBg={funnel.design?.modernContrastColor}
          isDisabled={isLeadSubmitting}
        />
        {/* for submit lead */}
        <MoveForwardButton
          isDisabled={
            Boolean(Object.keys(errors).length) ||
            emailDomainConfirmation ||
            isLeadSubmitting
          }
          handleClick={() => handleSubmit(onSubmit)()}
          buttonText={funnel.design?.modernSubmitButtonText || "Senden"}
          fill={false}
          isSubmitting={isLeadSubmitting}
          textColor={funnel.design?.modernSubmitButtonTextColor}
          btnBg={funnel.design?.modernSubmitButtonBackground}
          btnHoverBg={funnel.design?.modernSubmitButtonHoverBackground}
        />
      </ModerFormBtnContainer>
    </>
  );
};
export default ModernLeadForm;
