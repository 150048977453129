import { useDispatch, useSelector } from "react-redux";
import {
  updateMapImage,
  updateZipCodeValue,
  updateZipFilterData,
  updateIsZipSubmitted
} from "../store/slices/zipInfo";
import { checkPostalCode } from "../services/api";
import { INSIGHTS_TYPE } from "../templates/strings";
import { updateRecordActionState } from "../store/slices/funnelInfo";
import { countryMapHash } from "../components/ZipCodeForm/mapImageHash";

export const useZipcodeAction = () => {
  const dispatch = useDispatch();
  const {
    zipInfo,
    funnelInfo: { funnel }
  } = useSelector((state) => state);

  const handleUpdateZip = ({ value }) =>
    dispatch(updateZipCodeValue(value.slice(0, 5)));

  const handleZipcodeSubmit = () => {
    dispatch(updateIsZipSubmitted(!zipInfo.isSubmitted));
    dispatch(
      updateRecordActionState({
        insightRefId: INSIGHTS_TYPE.ZIP,
        insightType: INSIGHTS_TYPE.ZIP,
        nextInsightRefId: INSIGHTS_TYPE.LEAD,
        nextInsightType: INSIGHTS_TYPE.LEAD
      })
    );
    dispatch(updateZipCodeValue(zipInfo.value));
  };

  const handleCheckZipcode = async () => {
    try {
      const response = await checkPostalCode(funnel.id, zipInfo.value);
      dispatch(updateZipFilterData(response));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleMapImage = ({ country }) =>
    dispatch(updateMapImage(countryMapHash[country]));

  return {
    handleMapImage,
    handleUpdateZip,
    handleCheckZipcode,
    handleZipcodeSubmit
  };
};
