import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { Button, FormControl, FormLabel, ProgressBar } from "react-bootstrap";

const ModernVariantContainer = styled.div`
  font-family: "Gill Sans", sans-serif;
  min-height: 100vh;
`;
const ModernHeader = styled.div`
  max-width: 1400px;
  width: calc(100% - 2 * 20px);
`;
const HeaderLink = styled.a`
  color: ${({ color }) => color};
  text-decoration: none;
  &:hover {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
`;
const HeaderLinkLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-left: 12px;
`;
const HeaderIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  rotate: ${({ rotate }) => (rotate === "true" ? "-45deg" : "0")};
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
const ModernContainer = styled.div`
  padding: 30px 0; // for vertically center
  min-height: calc(100vh - 140px);
`;
const MainCenteredDiv = styled.div`
  width: calc(100% - 40px);
  max-width: 1160px;
`;
const CenteredContent = styled.div`
  min-height: 540px;
  border-radius: 10px 10px 0 0;
  background-color: ${({ bgOne }) => bgOne};
  background-image: ${({ bgTwo }) =>
    `linear-gradient(to top, ${bgTwo} 0%, ${bgTwo} 250px, transparent 251px`});
`;
const ModernHeadline = styled.h4`
  font-weight: 600;
  padding: 40px 20px;
  @media (max-width: 768px) {
    padding: 20px;
    font-size: calc(17px + 7 * (100vw - 520px) / 880);
  }
`;
const HeadlineAndTaglineContainer = styled.div`
  gap: 15px;
  max-width: 920px;
  margin-bottom: 20px;
  width: calc(100% - 40px);
`;
const FunnelHeadlineAndQuestionContainer = styled.div`
  border-radius: 10px;
  background: ${({ contrast }) => contrast};
  padding: ${({ questionPosition }) =>
    questionPosition === 0 ? "28px 30px" : "12px 23px 26px 30px"};
  @media (max-width: 768px) {
    gap: 8px;
    padding: 20px 22px !important;
  }
`;
const ModernFunnelHeadline = styled.div`
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.4;
  }
`;
const QuestionSubtext = styled.div`
  font-size: 14px;
  line-height: 1.4;
`;
const TaglineContainer = styled.div`
  border-radius: 10px;
  width: calc((100% - 45px) / 4);
  color: ${({ foreground }) => foreground};
  background: ${({ background }) => background};
`;
const TaglineContentFirst = styled.div`
  font-size: 14px;
`;
const TaglineContentSecond = styled.div`
  font-size: 24px;
  text-transform: uppercase;
`;
const BackgroundDiv = styled.div`
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-bottom: 25px;
  }
  &:after {
    content: "";
    width: 100%;
    max-width: 1160px;
    height: calc(100% - 50px);
    background-color: ${({ bgTwo }) => bgTwo};
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    z-index: 0;
  }
`;
const AnswersContainer = styled.div`
  gap: 15px;
  z-index: 1;
  max-width: 920px;
  width: calc(100% - 40px);
  @media (max-width: 768px) {
    gap: 15px;
  }
`;
const AnswerItem = styled.div`
  gap: 12px;
  cursor: pointer;
  max-height: 250px;
  min-height: 250px;
  border-radius: 10px;
  width: ${({ length }) =>
    `calc((100% - 45px) / ${length > 4 ? "4" : length})`};
  &:hover {
    color: ${({ bgColorBelow }) => bgColorBelow};
    &:after {
      opacity: 0.25;
    }
  }
  &:after {
    top: 6px;
    left: 6px;
    opacity: 0;
    content: "";
    position: absolute;
    border-radius: 10px;
    width: calc(100% - 12px);
    border: 3px solid #011b32;
    height: calc(100% - 12px);
    transition: opacity 300ms ease-in-out;
    @media (max-width: 768px) {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border-width: 2px;
      top: 4px;
      left: 4px;
    }
  }

  // hidden input(checkbox or radio)
  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    &:checked {
      opacity: 1;
      &:after {
        opacity: 1;
      }
    }
    &:after {
      top: 6px;
      left: 6px;
      opacity: 0;
      content: "";
      position: absolute;
      border-radius: 10px;
      width: calc(100% - 12px);
      border: solid 3px #011b32;
      height: calc(100% - 12px);
      transition: opacity 300ms ease-in-out;
      @media (max-width: 768px) {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border-width: 2px;
        top: 4px;
        left: 4px;
      }
    }
  }

  @media (max-width: 768px) {
    gap: 20px;
    width: 100%;
    min-height: 60px;
    max-height: calc((100% - 30px) / 4);
    padding: ${({ textOnlyPadding }) =>
      textOnlyPadding ? "20px !important;" : "10px 20px !important"};
    justify-content: flex-start !important;
  }
`;
const AnswerAsset = styled.img`
  width: ${({ ansLength }) => (ansLength === 6 ? "120px" : "150px")};
  height: ${({ ansLength }) => (ansLength === 6 ? "120px" : "150px")};
  @media (max-width: 768px) {
    width: 52px;
    height: auto;
  }
`;
const AnswerText = styled.div`
  font-size: 18px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
const USPContainer = styled.div`
  gap: 70px;
  padding: 32px 0;
  transform: translateY(2px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @media (max-width: 768px) {
    gap: 30px;
  }
`;
const USPItem = styled.div`
  gap: 12px;
  @media (max-width: 768px) {
    gap: 6px;
  }
`;
const USPLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ uspColor }) => uspColor};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
const CenteredUserPromptContainer = styled.div`
  width: 920px;
  border-radius: 10px;
  height: ${({ height }) => (height ? `${height}px` : "240px")};
  @media (max-width: 768px) {
    width: 100%;
    height: 320px;
    padding: ${({ forZip }) => (forZip ? `20px 0 20px` : `50px 0 30px`)};
  }
`;
const AnswerBadge = styled.div`
  top: 27px;
  left: 30px;
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 768px) {
    top: 17px;
    left: 20px;
    font-size: 10px;
  }
`;
const SliderInputContainer = styled.div`
  width: 170px;
  margin: 10px 0 30px;
  @media (max-width: 768px) {
    width: 150px;
  }
`;
const SliderInput = styled.input`
  border: none;
  outline: none;
  color: ${({ color }) => color};
  font-size: 36px;
  border-radius: 0;
  font-weight: 600;
  padding: 0 38px 10px;
  border-bottom: ${({ border }) => `solid 2px ${border}`};
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
const SliderRangeInfo = styled.div`
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 13px;
    width: 75% !important;
  }
`;
const RangeInputContainer = styled.div`
  @media (max-width: 768px) {
    width: 75% !important;
  }
`;
const SliderRange = styled.input.attrs({ type: "range" })`
  outline: 0;
  height: 4px;
  cursor: pointer;
  appearance: none;
  border-radius: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: ${(props) =>
    `linear-gradient(to right, currentColor ${
      ((props.value - props.min) * 100) / (props.max - props.min)
    }%, currentColor ${
      ((props.value - props.min) * 100) / (props.max - props.min)
    }%, #DBDBDB ${
      ((props.value - props.min) * 100) / (props.max - props.min)
    }%, #DBDBDB 100%);`};

  &:hover {
    // background: ${({ hoverColor }) => hoverColor};
  }
  &::-webkit-slider-thumb {
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    appearance: none;
    -webkit-appearance: none;
    border: 3px solid currentColor;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
  }
  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    appearance: none;
    -moz-appearance: none;
    border: 3px solid currentColor;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
  }
`;
const ButtonContainer = styled.div`
  gap: 20px;
  z-index: 1;
  max-width: 920px;
  width: calc(100% - 40px);
  @media (max-width: 768px) {
    gap: 15px;
  }
`;
const BackButton = styled.button`
  width: 180px;
  background-color: ${({ contrastBg }) => contrastBg};
  padding: 13px;
  background-repeat: no-repeat;
  transition: opacity 100ms ease-in-out;
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
  &:hover {
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    &:after {
      background-color: #011b32; // should define hover color
      right: 0;
      transition-duration: 180ms;
      width: ${({ disabled }) => (disabled ? "0" : "100%")};
    }
  }
  &:after {
    content: "";
    height: 100%;
    width: 0;
    color: transparent;
    white-space: nowrap;
    position: absolute;
    top: 0;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    transition-property: width, color;
  }
  @media (max-width: 768px) {
    width: calc(50% - 7.5px);
  }
`;
const NextButton = styled.button`
  padding: 13px;
  background-repeat: no-repeat;
  transition: opacity 100ms ease-in-out;
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1")};
  border-radius: 5px;
  overflow: hidden;
  padding-right: 33px;
  background-color: ${({ lightBg }) => lightBg};
  margin-top: 20px;
  width: ${({ fill }) => (fill === "false" ? "calc(50% - 10px)" : "")};
  &:hover {
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    &:after {
      background-color: ${({ hoverBg }) => hoverBg};
      left: 0;
      width: ${({ disabled }) => (disabled ? "0" : "100%")};
    }
  }
  &:after {
    content: "";
    height: 100%;
    width: 0;
    color: transparent;
    white-space: nowrap;
    position: absolute;
    top: 0;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    transition-property: width, color;
  }
`;
const BtnSpan = styled.span`
  z-index: 1;
  color: ${({ color }) => color};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const ProgressContainer = styled.div`
  gap: 20px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;
const Progress = styled(ProgressBar)`
  height: 3px;
  @media (max-width: 768px) {
    height: 2px;
  }
`;
const ProgressInfo = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
// const QuestionLabel = styled.div`
//   font-size: 12px;
//   font-weight: 700;
//   @media (max-width: 768px) {
//     font-size: 10px;
//     margin-top: -4px;
//   }
// `;
const QuestionText = styled.h4`
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const CenteredInnerContainer = styled.div`
  width: 600px;
  font-size: 14px;
  font-weight: 800;
  overflow: scroll;
  max-width: calc(100% - 50px);
`;
const CenteredInputLabel = styled(FormLabel)`
  color: #171717;
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 15px;
`;
const CenteredInput = styled(FormControl)`
  height: 48px;
  padding: 14px 19px;
  border-radius: 5px;
  border: 1px solid #898989;
  background-color: #f2f2f2;
  &:focus {
    box-shadow: none;
    border-color: #898989;
    background-color: #f2f2f2;
  }
`;
const FooterContainer = styled.div`
  bottom: 15px;
  color: #adadad;
`;
const OutcomeContainer = styled.div`
  top: 50%;
  left: 50%;
  overflow: scroll;
  max-width: 920px;
  width: calc(100% - 40px);
  max-height: calc(100% - 50px);
  transform: translate(-50%, -50%);
`;
const StickyHeadline = styled.h4`
  font-weight: 600;
`;
const ModernAnimationContainer = styled.div`
  z-index: 1;
  position: relative;
  animation: slideToCenterFromRight 0.7s;
  @keyframes slideToCenterFromRight {
    from {
      right: -150px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }
`;

const ModernSmContainer = styled.div`
  @media (max-width: 768px) {
    margin: 0 auto;
    max-width: calc(100% - 50px);
  }
`;
const ZipScreenImage = styled.img`
  width: 150px;
  height: 150px;
  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;
const ModernFormContainer = styled.div`
  gap: 20px;
  max-width: 920px;
  width: calc(100% - 40px);
  @media (max-width: 768px) {
    gap: 15px;
  }
`;
const ModernFormSection = styled.div`
  gap: 20px;
  z-index: 1;
  width: calc(100% - 10px);
`;
const FormFieldInfo = styled.div`
  border-radius: 10px;
  padding: 0px 20px 20px;
  background-color: ${({ background }) => background};
`;
const FormFieldInfoHeading = styled.h4`
  font-size: 22px;
  font-weight: 600;
  padding: 20px 0px 0px;
`;
const FormFieldInfoPara = styled.p`
  padding: 20px 0 0;
  @media (max-width: 768px) {
    font-size: 14px;
    padding-top: 8px;
  }
`;
const FormFieldInfoSpan = styled.span`
  gap: 10px;
  font-size: 13px;
  font-weight: 600;
  margin: 30px 0 -5px;
`;
const ModerFormBtnContainer = styled.div`
  gap: 20px;
  margin-top: 20px;
  max-width: 920px;
  width: calc(100% - 40px);
`;
const ModernFormFieldsSection = styled.div`
  width: calc(100% - 10px);
  border-radius: 10px;
`;
const ModernForm = styled.form`
  padding: 0px 20px 20px;
`;
const UserConfirmationLabel = styled.label`
  margin-left: 10px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: #171717;
  width: calc(100% - 18px - 10px);
`;
const ModernFormFieldWrapper = styled.div`
  margin-top: 20px;
`;
const ThankyouBtn = styled(Button)`
  border: none;
  outline: none;
  background-color: ${({ background }) => background};
  &:hover {
    background-color: ${({ background }) => background} !important;
  }
`;
const PhoneInputStyles = styled(PhoneInputWithCountry)`
  // select styling
  .PhoneInputCountry {
    border-radius: 5px 0 0 5px;
    border: 1px solid rgb(137, 137, 137) !important;
    background-color: rgb(242, 242, 242) !important;
  }
  // input styling
  input {
    height: 48px !important;
    padding: 14px 19px;
    border: 1px solid rgb(137, 137, 137) !important;
    border-left: 0 !important;
    background-color: rgb(242, 242, 242);
    &:focus {
      box-shadow: none !important;
      border-color: #898989 !important;
      background-color: #f2f2f2 !important;
    }
  }
`;

export {
  BtnSpan,
  USPItem,
  USPLabel,
  Progress,
  NextButton,
  BackButton,
  HeaderLink,
  HeaderIcon,
  AnswerItem,
  AnswerText,
  ModernForm,
  ThankyouBtn,
  AnswerAsset,
  SliderRange,
  SliderInput,
  AnswerBadge,
  ProgressInfo,
  ModernHeader,
  USPContainer,
  QuestionText,
  FormFieldInfo,
  BackgroundDiv,
  CenteredInput,
  StickyHeadline,
  ZipScreenImage,
  ModernHeadline,
  FooterContainer,
  SliderRangeInfo,
  QuestionSubtext,
  HeaderLinkLabel,
  ButtonContainer,
  ModernContainer,
  MainCenteredDiv,
  CenteredContent,
  TaglineContainer,
  PhoneInputStyles,
  AnswersContainer,
  OutcomeContainer,
  FormFieldInfoSpan,
  FormFieldInfoPara,
  ProgressContainer,
  ModernFormSection,
  ModernSmContainer,
  CenteredInputLabel,
  RangeInputContainer,
  ModernFormContainer,
  TaglineContentFirst,
  FormFieldInfoHeading,
  SliderInputContainer,
  TaglineContentSecond,
  ModernFunnelHeadline,
  UserConfirmationLabel,
  ModerFormBtnContainer,
  ModernVariantContainer,
  CenteredInnerContainer,
  ModernFormFieldWrapper,
  ModernFormFieldsSection,
  ModernAnimationContainer,
  CenteredUserPromptContainer,
  HeadlineAndTaglineContainer,
  FunnelHeadlineAndQuestionContainer
};
