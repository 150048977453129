import { createSlice } from "@reduxjs/toolkit";
import { isNumeric } from "../../services/utils";
import {
  FUNNEL_DESIGNS,
  QUESTION_POSITION,
  QUESTION_TYPE
} from "../../templates/strings";

const initialState = {
  funnel: {},
  outcome: {},
  allQuestions: [],
  qResponses: [],
  currentQuestion: 0,
  questionToRemove: "",
  doRemove: false,
  isFormBack: false,
  previousQuestionStack: [],
  sliderValue: 0,
  freeTextPrevValue: "",
  selectedAnswers: {},
  redirectUrlParams: {
    email: "",
    firstName: "",
    lastName: "",
    phone: ""
  },
  leadForm: {
    isSubmitted: false
  },
  recordActionState: {},
  modalData: { show: false, heading: "", content: "" },
  emailDomainConfirmation: false
};
export const funnelInfoSlice = createSlice({
  name: "funnelInfo",
  initialState,
  reducers: {
    setFunnel: (state, action) => {
      state.funnel = action.payload;
    },
    updateDoRemove: (state, action) => {
      state.doRemove = !state.doRemove;
      if (action.payload.isForm) {
        state.isFormBack = true;
      }
    },
    goBack: (state) => {
      state.isFormBack = false;
      state.doRemove = true;
      const lastItem = state.previousQuestionStack.pop();
      state.questionToRemove = lastItem.nextQuestionPosition;
      const previousAnswer = { ...lastItem, answer: { ...lastItem.answer } };
      state.currentQuestion = previousAnswer.position;
      state.selectedAnswers = previousAnswer.isMultiSelect
        ? previousAnswer.answer
        : {};
      state.sliderValue =
        previousAnswer.type === QUESTION_TYPE.SLIDER
          ? previousAnswer.answer.selectedAnswer
          : 0;
      state.freeTextPrevValue =
        previousAnswer.type === QUESTION_TYPE.FREE_TEXT_FIELD
          ? previousAnswer.answer.selectedAnswer
          : "";
      state.qResponses.pop();
    },

    updateCurrentQuestion: (state, action) => {
      const { position, question } = action.payload;
      let nextQuestionIndex;
      const jumpForward = state.currentQuestion + 1;
      if (isNumeric(position)) {
        nextQuestionIndex = parseInt(position);
      } else if (position === QUESTION_POSITION.next) {
        // next and multi both move to forward
        nextQuestionIndex = jumpForward;
      } else if (position === QUESTION_POSITION.last) {
        nextQuestionIndex = state.allQuestions.length;
      } else {
        // Default to next question
        nextQuestionIndex = jumpForward;
      }
      // storing previous question when jumping to next qs in Modern variant
      if (state.funnel.funnelDesign === FUNNEL_DESIGNS.MODERN) {
        const prevQues = {
          nextQuestionPosition: nextQuestionIndex,
          position: state.currentQuestion,
          type: question.type,
          isMultiSelect: question.isMultiSelectable,
          answer: question.isMultiSelectable
            ? { ...state.selectedAnswers }
            : { ...state.qResponses[state.qResponses.length - 1] }
        };
        state.previousQuestionStack.push(prevQues);
        state.sliderValue = 0;
        state.doRemove = false;
        state.freeTextPrevValue = "";
        state.questionToRemove = null;
      }

      state.currentQuestion = nextQuestionIndex;
    },

    updateSelectedValue: (state, action) => {
      state.qResponses.push({ ...action.payload });
    },

    updateQA: (state, action) => {
      state.allQuestions = action.payload;
    },

    updateOutcome: (state, action) => {
      state.outcome = action.payload;
    },

    restartQuestionnaire: (state, action) => {
      state.qResponses = [];
      state.currentQuestion = 0;
      state.outcome = {};
    },

    addMultiSelectAnswers: (state, action) => {
      const ans = action.payload.answer;
      const clone = { ...state.selectedAnswers };
      if (clone.hasOwnProperty.call(clone, ans._id)) {
        delete clone[ans._id];
      } else {
        clone[ans._id] = ans.text;
      }
      state.selectedAnswers = clone;
    },

    clearMultiSelectAnswers: (state, action) => {
      state.selectedAnswers = {};
    },

    updateUrlParams: (state, action) => {
      state.redirectUrlParams = action.payload;
    },

    updateIsFormSubmitted: (state, action) => {
      state.leadForm.isSubmitted = action.payload;
    },

    updateRecordActionState: (state, action) => {
      state.recordActionState = action.payload;
    },

    updateModalData: (state, action) => {
      state.modalData = action.payload;
    },

    updateEmailDomainConfirmation: (state, action) => {
      state.emailDomainConfirmation = action.payload;
    }
  }
});

export const {
  goBack,
  updateQA,
  setFunnel,
  updateOutcome,
  updateUrlParams,
  updateModalData,
  updateSelectedValue,
  restartQuestionnaire,
  updateCurrentQuestion,
  updateIsFormSubmitted,
  addMultiSelectAnswers,
  clearMultiSelectAnswers,
  updateRecordActionState,
  updateEmailDomainConfirmation,
  updateDoRemove
} = funnelInfoSlice.actions;
export default funnelInfoSlice.reducer;
